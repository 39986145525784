const CONFIG = {
    BREAKPOINTS: { MOBILE: 768 },
    TIMING: {
        ANIMATION_DURATION: 300,
        SCROLL_THRESHOLD: 0.1,
        SLIDER_INTERVAL: 12000
    },
    CLASSES: {
        ACTIVE: "active",
        VISIBLE: "visible"
    },
    CATEGORIES: {
        manuscript: "Manuscript",
        preprint: "Preprint",
        book: "Book Chapter",
        review: "Review"
    }
};

const OBSERVER_OPTIONS = {
    root: null,
    threshold: 0.1,
    rootMargin: "0px 0px -50px 0px"
};

export { CONFIG, OBSERVER_OPTIONS };
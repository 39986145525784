import { performanceOptimizer } from "./performance.js";
import { CONFIG } from "./config.js";
import Utils from "./utils.js";
import { initializeNavigation } from "./navigation.js"; // Keep this as is
import { initializeTabs } from "./tabs.js";
import { PublicationsManager } from "./publications.js";
import { NewsManager } from "./news.js";
import { ProtocolManager } from "./protocols.js";
import { animationObserver } from "./observers.js";
import { SliderManager } from "./slider.js";

// Initialize everything when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  try {
    // Initialize core components
    initializeNavigation(); // Just call the function directly

    // Initialize tabs
    document
      .querySelectorAll(".vertical-tabs, .vertical-tabs-2")
      .forEach((container, index) => initializeTabs(container, index === 1));

    // Initialize Publications Manager
    if (document.querySelector(".publications-section")) {
      try {
        const pubManager = new PublicationsManager();
        console.log("Publications manager initialized successfully");
      } catch (error) {
        console.error("Error initializing Publications Manager:", error);
      }
    }

    // Initialize News Manager
    if (document.querySelector(".news-page")) {
      if (!window.newsData) {
        console.error("News data not found");
      } else {
        console.log("Initializing News with:", window.newsData);
        new NewsManager({
          gridSelector: ".news-grid",
          filterSelector: ".news-filter-btn"
        });
      }
    }

    // Initialize Home Page News
    if (document.querySelector(".index-page")) {
      if (!window.newsData) {
        console.error("News data not found for index page");
      } else {
        //console.log("Initializing Index News with:", window.newsData);
        new NewsManager({
          gridSelector: ".news-grid-home",
          showOnlyYear: "2024",
          maxItems: 4
        });
      }
    }

    // Setup animations
    const animatedElements = document.querySelectorAll(
      ".reveal-left, .reveal-right, .profile-card"
    );

    animatedElements.forEach((element) => {
      if (element.classList.contains("profile-card")) {
        element.dataset.animation = "reveal";
      }
      animationObserver.observe(element);
    });

    // Initialize media optimizer
    mediaOptimizer.init();
  } catch (error) {
    console.error("Initialization error:", error);
  }
});

// Media Optimizer
const mediaOptimizer = {
  init() {
    this.lazyLoadImages();
    this.optimizeVideos();
    this.preloadCriticalAssets();
  },

  lazyLoadImages() {
    const images = document.querySelectorAll('img[loading="lazy"]');
    const imageObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            if (img.dataset.src) {
              img.src = img.dataset.src;
              img.removeAttribute("data-src");
            }
            imageObserver.unobserve(img);
          }
        });
      },
      { rootMargin: "50px" }
    );

    images.forEach((img) => imageObserver.observe(img));
  },

  optimizeVideos() {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.preload = "none";

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              video.play().catch(() => {}); // Handle autoplay restrictions
            } else {
              video.pause();
            }
          });
        },
        { threshold: 0.1 }
      );

      observer.observe(video);
    });
  },

  preloadCriticalAssets() {
    const heroVideo = document.querySelector(".hero-video source");
    if (heroVideo) {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "video";
      link.href = heroVideo.src;
      document.head.appendChild(link);
    }
  }
};

function removeWhitespaceNodes(element) {
  for (let i = element.childNodes.length - 1; i >= 0; i--) {
    let child = element.childNodes[i];

    if (child.nodeType === Node.TEXT_NODE && /^\s*$/.test(child.textContent)) {
      element.removeChild(child);
    } else if (child.childNodes.length > 0) {
      removeWhitespaceNodes(child); // Recursively check child nodes
    }
  }
}

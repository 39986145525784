import { CONFIG } from "./config.js";
import Utils from "./utils.js";

export function initializeTabs(tabsContainer, isSecondSet) {
  const tabButtons = tabsContainer.querySelectorAll(".tab-button");
  const tabPanels = tabsContainer.querySelectorAll(".tab-panel");
  const containerClass = isSecondSet ? ".vertical-tabs-2" : ".vertical-tabs";

  if (!tabButtons.length || !tabPanels.length) return;

  // Set initial state
  if (!tabsContainer.querySelector(".tab-button.active")) {
    tabButtons[0]?.classList.add("active");
    tabPanels[0]?.classList.add("active");
  }

  tabButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const targetTab = button.getAttribute("data-tab");
      const isMobile = Utils.isMobile();

      // Update buttons
      tabButtons.forEach((btn) => btn.classList.remove("active"));
      button.classList.add("active");

      // Update panels
      tabPanels.forEach((panel) => {
        const isTarget = panel.id === targetTab;
        panel.classList.toggle("active", isTarget);

        if (!isMobile) {
          panel.style.transform = isTarget
            ? "translateX(0)"
            : "translateX(-100%)";
          panel.style.opacity = isTarget ? "1" : "0";
        }
      });

      // Mobile scroll
      if (isMobile) {
        const activePanel = tabsContainer.querySelector(`#${targetTab}`);
        activePanel?.scrollIntoView({ behavior: "smooth" });
      }
    });
  });
}

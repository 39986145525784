const Utils = {
  isMobile: () => window.innerWidth <= 768,

  debounce(func, wait = 250) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  },

  formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  },

  truncateText(text, maxLength) {
    if (!text) return "";
    return text.length <= maxLength
      ? text
      : text.substring(0, maxLength).trim() + "...";
  }
};

export default Utils;



import { CONFIG } from "./config.js";
import { animationObserver } from "./observers.js";

export class NewsManager {
  constructor(options = {}) {
    // Initialize properties first
    this.options = options;
    this.grid = null;
    this.news = null;
    this.filterButtons = null;
    this.currentYear = options.defaultYear || "all";
    this.showOnlyYear = options.showOnlyYear || null;
    this.maxItems = options.maxItems || null;

    // Call initialize instead of init
    this.initialize();
  }

  initialize() {
    try {
      // Check for news data
      if (typeof window.newsItems === "undefined") {
        console.error("News data not available");
        return;
      }
      this.news = window.newsItems;

      // Find the grid element
      this.grid = document.querySelector(
        this.options.gridSelector || ".news-grid"
      );
      if (!this.grid) {
        console.error(
          `News grid not found with selector: ${this.options.gridSelector}`
        );
        return;
      }

      // Set up filter buttons if needed
      if (!this.showOnlyYear) {
        this.filterButtons = document.querySelectorAll(
          this.options.filterSelector || ".filter-btn"
        );
        this.setupFilterListeners();
      }


      // Render the news
      this.renderNews();
    } catch (error) {
      console.error("News initialization error:", error);
      if (this.grid) {
        this.grid.innerHTML =
          '<div class="error-message">Error loading news.</div>';
      }
    }
  }

  renderNews() {
    try {
      if (!this.grid || !this.news || !this.news.length) {
        console.error("Cannot render news: missing required elements");
        return;
      }

      // Clear the grid
      this.grid.innerHTML = "";

      // Filter and sort news
      let filteredNews = [...this.news];

      if (this.showOnlyYear) {
        filteredNews = filteredNews.filter(
          (item) => item.year === this.showOnlyYear
        );
      } else if (this.currentYear !== "all") {
        filteredNews = filteredNews.filter(
          (item) => item.year === this.currentYear
        );
      }

      // Sort by date
      filteredNews.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Apply item limit if specified
      if (this.maxItems) {
        filteredNews = filteredNews.slice(0, this.maxItems);
      }

      if (filteredNews.length === 0) {
        this.grid.innerHTML =
          '<div class="no-news">No news available for this year.</div>';
        return;
      }

      // Render news cards
      filteredNews.forEach((item, index) => {
        const card = this.createNewsCard(item);
        this.grid.appendChild(card);

        // Animate cards
        requestAnimationFrame(() => {
          setTimeout(() => {
            card.classList.add(CONFIG.CLASSES.VISIBLE);
            animationObserver.observe(card);
          }, index * 100);
        });
      });
    } catch (error) {
      console.error("Error rendering news:", error);
      this.grid.innerHTML =
        '<div class="error-message">Error displaying news.</div>';
    }
  }

  createNewsCard(item) {
    const excerpt = this.truncateExcerpt(item.excerpt, 150);
    const card = document.createElement("article");
    card.className = "news-card";
    card.dataset.year = item.year;

    const linkElement =
      item.link && item.link !== "#"
        ? `<a href="${item.link}" class="news-link" target="_blank">
           Read More
           <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
             <path d="M6 12L10 8L6 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
         </a>`
        : "";

    card.innerHTML = `
      <div class="news-image">
        <img src="${item.image}" alt="${item.title}" loading="lazy">
      </div>
      <div class="news-content">
        <div class="news-date">${this.formatDate(item.date)}</div>
        <h3 class="news-title">${item.title}</h3>
        <p class="news-excerpt">${excerpt}</p>
        ${linkElement}
      </div>
    `;

    return card;
  }

  formatDate(dateString) {
    if (!dateString) return "Date not specified";
    try {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    } catch (error) {
      return dateString;
    }
  }

  truncateExcerpt(text, maxLength = 150) {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength).trim() + "...";
  }

  setupFilterListeners() {
    if (!this.filterButtons) return;

    this.filterButtons.forEach((btn) => {
      btn.addEventListener("click", () => {
        this.filterButtons.forEach((b) =>
          b.classList.remove(CONFIG.CLASSES.ACTIVE)
        );
        btn.classList.add(CONFIG.CLASSES.ACTIVE);
        this.currentYear = btn.dataset.year;
        this.renderNews();
      });
    });
  }
}

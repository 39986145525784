// observers.js
const OBSERVER_OPTIONS = {
  root: null,
  threshold: 0.1,
  rootMargin: "0px 0px -100px 0px" // Adjusted rootMargin for earlier observation
};

const animationObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("visible");
      animationObserver.unobserve(entry.target); // Stop observing after animation
    }
  });
}, OBSERVER_OPTIONS);

export { animationObserver };

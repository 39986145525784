// publications.js
import { CONFIG, OBSERVER_OPTIONS } from "./config.js";

export class PublicationsManager {
  constructor() {
    if (typeof window.publicationsData === "undefined") {
      console.error("Publications data not available");
      return;
    }

    this.grid = document.querySelector(".publications-grid");
    if (!this.grid) {
      console.error("Publications grid not found");
      return;
    }

    this.publications = window.publicationsData;
    this.filterButtons = document.querySelectorAll(".publications-filter-btn");
    this.currentFilter = "all";
    this.observer = this.createObserver();

    this.init();
  }

  init() {
    try {
      this.sortPublications();
      this.renderPublications();
      this.setupFilterListeners();
    } catch (error) {
      console.error("Publications initialization error:", error);
      this.handleError(error);
    }
  }

  createObserver() {
    return new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(CONFIG.CLASSES.VISIBLE);
          this.observer.unobserve(entry.target); // Stop observing after animation
        }
      });
    }, OBSERVER_OPTIONS);
  }

  sortPublications() {
    this.publications.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  }

  createPublicationCard(publication) {
    const specificContent = this.getSpecificContent(publication);
    const readMoreLink = publication.link
      ? this.createReadMoreLink(publication.link)
      : "";

    return `
      <div class="publication-card" data-category="${
        publication.category
      }" data-year="${publication.year}">
        <span class="publication-category" data-category="${
          publication.category
        }">
          ${this.formatCategory(publication.category)}
        </span>
        <h3 class="publication-title">${publication.title}</h3>
        <p class="publication-authors">${publication.authors}</p>
        ${specificContent}
        <p class="publication-year">${publication.year}</p>
        ${readMoreLink}
      </div>
    `;
  }

  getSpecificContent(publication) {
    switch (publication.category) {
      case "book-chapter":
        return `
          <p class="publication-book">${publication.book}</p>
          ${
            publication.ISBN
              ? `<p class="publication-ISBN">ISBN: ${publication.ISBN}</p>`
              : ""
          }
        `;
      case "review":
      case "manuscript":
      case "preprint":
        return `<p class="publication-journal">${publication.journal}</p>`;
      default:
        return publication.journal
          ? `<p class="publication-journal">${publication.journal}</p>`
          : publication.book
          ? `<p class="publication-book">${publication.book}</p>
             ${
               publication.ISBN
                 ? `<p class="publication-ISBN">ISBN: ${publication.ISBN}</p>`
                 : ""
             }`
          : "";
    }
  }

  createReadMoreLink(link) {
    return `
      <a href="${link}" class="publication-link" target="_blank">
        Read More
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M6 12L10 8L6 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
    `;
  }

  formatCategory(category) {
    return category.charAt(0).toUpperCase() + category.slice(1);
  }

  renderPublications() {
    const fragment = document.createDocumentFragment();
    const filteredPublications = this.publications.filter(
      (pub) =>
        this.currentFilter === "all" || pub.category === this.currentFilter
    );

    if (filteredPublications.length === 0) {
      this.grid.innerHTML =
        '<div class="no-publications">No publications found in this category.</div>';
      return;
    }

    filteredPublications.forEach((pub) => {
      const card = document.createElement("div");
      card.innerHTML = this.createPublicationCard(pub);
      fragment.appendChild(card);
    });

    this.grid.innerHTML = "";
    this.grid.appendChild(fragment);
    this.setupAnimations();
  }

  setupAnimations() {
    const cards = this.grid.querySelectorAll(".publication-card");
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.style.transitionDelay = `${
          index * (CONFIG.TIMING.ANIMATION_DURATION / 4)
        }ms`;
        this.observer.observe(card);
      }, 0);
    });
  }

  setupFilterListeners() {
    this.filterButtons?.forEach((btn) => {
      btn.addEventListener("click", () => {
        this.filterButtons.forEach((b) =>
          b.classList.remove(CONFIG.CLASSES.ACTIVE)
        );
        btn.classList.add(CONFIG.CLASSES.ACTIVE);
        this.currentFilter = btn.dataset.filter;
        this.renderPublications();
      });
    });
  }

  handleError(error) {
    this.grid.innerHTML =
      '<div class="error-message">Error loading publications.</div>';
  }
}

// Initialize when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".publications-grid")) {
    new PublicationsManager();
  }
});

import { CONFIG } from "./config.js";
import { animationObserver } from "./observers.js";

export class ProtocolManager {
  constructor() {
    // Check for data availability using the correct variable name
    if (typeof window.protocolData === "undefined") {
      console.error("Protocol data not available");
      return;
    }

    this.grid = document.querySelector(".protocols-grid");
    if (!this.grid) {
      console.error("Protocols grid not found");
      return;
    }

    this.protocols = window.protocolData; // Use the correct variable name
    this.filterButtons = document.querySelectorAll(".protocol-filter-btn");
    this.currentFilter = "all";

    console.log("ProtocolManager initialized with:", this.protocols); // Debug log
    this.init();
  }

  init() {
    try {
      if (!this.protocols || !this.protocols.length) {
        this.grid.innerHTML =
          '<div class="no-protocols">No protocols available.</div>';
        return;
      }

      this.renderProtocols();
      this.setupFilterListeners();
      this.setupScrollReveal();
    } catch (error) {
      console.error("Protocol initialization error:", error);
      this.grid.innerHTML =
        '<div class="error-message">Error loading protocols.</div>';
    }
  }

  createProtocolCard(protocol) {
    return `
            <div class="protocol-card" data-category="${
              protocol.category || "uncategorized"
            }">
                <div class="protocol-content">
                    <h4>Title:</h4>
                    <p class="protocol-title">${protocol.title}</p>
                    <h4>Prepared By:</h4>
                    <p class="protocol-authors">${
                      protocol.author || "Not specified"
                    }</p>
                    <h4>Version:</h4>
                    <p class="protocol-version">${protocol.version || "1.0"}</p>
                    <h4>Date:</h4>
                    <p class="protocol-date">${this.formatDate(
                      protocol.date
                    )}</p>
                    ${
                      protocol.downloadLink
                        ? `
                        <a href="${protocol.downloadLink}" class="protocol-link" target="_blank">
                            Download
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M6 12L10 8L6 4" stroke="currentColor" stroke-width="2" 
                                stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    `
                        : ""
                    }
                </div>
            </div>
        `;
  }

  formatDate(dateString) {
    if (!dateString) return "Not specified";
    try {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    } catch (error) {
      return dateString;
    }
  }

  renderProtocols() {
    try {
      let filteredProtocols = [...this.protocols];

      if (this.currentFilter !== "all") {
        filteredProtocols = filteredProtocols.filter(
          (p) => p.category?.toLowerCase() === this.currentFilter.toLowerCase()
        );
      }

      // Sort by date (newest first)
      filteredProtocols.sort((a, b) => new Date(b.date) - new Date(a.date));

      this.grid.innerHTML = filteredProtocols.length
        ? filteredProtocols.map((p) => this.createProtocolCard(p)).join("")
        : '<div class="no-protocols">No protocols found for this category.</div>';

      this.animateCards();
    } catch (error) {
      console.error("Error rendering protocols:", error);
      this.grid.innerHTML =
        '<div class="error-message">Error displaying protocols.</div>';
    }
  }

  animateCards() {
    requestAnimationFrame(() => {
      document.querySelectorAll(".protocol-card").forEach((card, index) => {
        setTimeout(
          () => card.classList.add(CONFIG.CLASSES.VISIBLE),
          index * 100
        );
      });
    });
  }

  setupFilterListeners() {
    this.filterButtons?.forEach((btn) => {
      btn.addEventListener("click", () => {
        this.filterButtons.forEach((b) =>
          b.classList.remove(CONFIG.CLASSES.ACTIVE)
        );
        btn.classList.add(CONFIG.CLASSES.ACTIVE);
        this.currentFilter = btn.dataset.filter;
        this.renderProtocols();
      });
    });
  }

  setupScrollReveal() {
    document.querySelectorAll(".protocol-card").forEach((card) => {
      animationObserver.observe(card);
    });
  }
}

// Initialize when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".protocols-grid")) {
    new ProtocolManager();
  }
});

// slider.js
class Slider {
  constructor() {
    this.slides = document.querySelectorAll(".slide");
    this.dots = document.querySelectorAll(".nav-dot");
    this.currentSlide = 0;
    this.interval = null;
    this.isAnimating = false;

    // Only initialize if elements exist
    if (this.slides.length > 0 && this.dots.length > 0) {
      this.init();
    } else {
      console.log("Slider elements not found");
      return;
    }
  }

  init() {
    // Show first slide
    this.showSlide(0);

    // Setup event listeners
    this.setupEventListeners();

    // Start autoplay
    this.startAutoplay();
  }

  showSlide(index) {
    if (this.isAnimating) return;
    this.isAnimating = true;

    // Remove active classes
    this.slides[this.currentSlide].classList.remove("active");
    this.dots[this.currentSlide].classList.remove("active");

    // Update current slide
    this.currentSlide = index;

    // Add active classes
    this.slides[this.currentSlide].classList.add("active");
    this.dots[this.currentSlide].classList.add("active");

    // Reset animation lock after transition
    setTimeout(() => {
      this.isAnimating = false;
    }, 800); // Match your CSS transition duration
  }

  setupEventListeners() {
    // Dot navigation
    this.dots.forEach((dot, index) => {
      dot.addEventListener("click", () => {
        this.showSlide(index);
        this.resetAutoplay();
      });
    });

    // Touch events for mobile
    let touchStartX = 0;
    const sliderContainer = document.querySelector(".slider-container");

    if (sliderContainer) {
      sliderContainer.addEventListener(
        "touchstart",
        (e) => {
          touchStartX = e.touches[0].clientX;
          this.pauseAutoplay();
        },
        { passive: true }
      );

      sliderContainer.addEventListener(
        "touchend",
        (e) => {
          const touchEndX = e.changedTouches[0].clientX;
          const diff = touchStartX - touchEndX;

          if (Math.abs(diff) > 50) {
            // Minimum swipe distance
            if (diff > 0) {
              this.nextSlide();
            } else {
              this.previousSlide();
            }
          }
          this.startAutoplay();
        },
        { passive: true }
      );

      // Pause on hover
      sliderContainer.addEventListener("mouseenter", () =>
        this.pauseAutoplay()
      );
      sliderContainer.addEventListener("mouseleave", () =>
        this.startAutoplay()
      );
    }
  }

  nextSlide() {
    this.showSlide((this.currentSlide + 1) % this.slides.length);
  }

  previousSlide() {
    this.showSlide(
      (this.currentSlide - 1 + this.slides.length) % this.slides.length
    );
  }

  startAutoplay() {
    if (this.interval) this.pauseAutoplay();
    this.interval = setInterval(() => this.nextSlide(), 12000); // 12 seconds as in your original code
  }

  pauseAutoplay() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  resetAutoplay() {
    this.pauseAutoplay();
    this.startAutoplay();
  }
}

// Initialize slider when DOM is loaded
document.addEventListener("DOMContentLoaded", () => {
  // Make goToSlide available globally for the nav dots
  window.goToSlide = function (index) {
    if (window.sliderInstance) {
      window.sliderInstance.showSlide(index);
      window.sliderInstance.resetAutoplay();
    }
  };

  // Initialize slider
  if (document.querySelector(".slide")) {
    window.sliderInstance = new Slider();
  }
});

// performance.js
export const performanceOptimizer = {
  init() {
    this.setupLazyLoading();
    this.optimizeAnimations();
    this.deferNonCritical();
    this.preloadCriticalResources();
  },

  setupLazyLoading() {
    const lazyImages = document.querySelectorAll('img[loading="lazy"]');
    const imageObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            if (img.dataset.src) {
              img.src = img.dataset.src;
              img.removeAttribute("data-src");
            }
            imageObserver.unobserve(img);
          }
        });
      },
      { rootMargin: "50px" }
    );

    lazyImages.forEach((img) => imageObserver.observe(img));
  },

  optimizeAnimations() {
    if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      document.documentElement.style.setProperty(
        "--animation-duration",
        "0.1s"
      );
    }

    const animatedElements = document.querySelectorAll(
      ".reveal-left, .reveal-right"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.style.animationPlayState = entry.isIntersecting
            ? "running"
            : "paused";
        });
      },
      { threshold: 0.1 }
    );

    animatedElements.forEach((el) => observer.observe(el));
  },

  deferNonCritical() {
    const deferredScripts = document.querySelectorAll("script[data-defer]");
    window.addEventListener("load", () => {
      requestIdleCallback(() => {
        deferredScripts.forEach((script) => {
          const newScript = document.createElement("script");
          [...script.attributes].forEach((attr) => {
            newScript.setAttribute(attr.name, attr.value);
          });
          newScript.innerHTML = script.innerHTML;
          script.parentNode.replaceChild(newScript, script);
        });
      });
    });
  },

  preloadCriticalResources() {
    const criticalResources = [
      { href: "path/to/hero-image.jpg", as: "image" },
      { href: "path/to/critical-font.woff2", as: "font", crossorigin: true }
    ];

    criticalResources.forEach((resource) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = resource.href;
      link.as = resource.as;
      if (resource.crossorigin) link.crossOrigin = "anonymous";
      document.head.appendChild(link);
    });
  }
};

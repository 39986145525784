// navigation.js
export function initializeNavigation() {
  const hamburger = document.querySelector(".hamburger");
  const navbar = document.querySelector(".navbar");
  if (!hamburger || !navbar) return;

  // Toggle menu function
  const toggleMenu = () => {
    navbar.classList.toggle("active");
    document.body.style.overflow = navbar.classList.contains("active")
      ? "hidden"
      : "";
  };

  // Scroll handler function with immediate response
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 20; // Changed to 20px as requested

    // Use RequestAnimationFrame for smooth performance
    requestAnimationFrame(() => {
      if (scrollPosition > scrollThreshold) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    });
  };

  // Add scroll event listener without debounce for immediate response
  window.addEventListener("scroll", handleScroll, { passive: true });

  // Check initial scroll position
  handleScroll();

  // Hamburger click event
  hamburger.addEventListener("click", (e) => {
    e.stopPropagation();
    toggleMenu();
  });

  // Close menu when clicking outside
  document.addEventListener("click", (e) => {
    if (
      !navbar.contains(e.target) &&
      !hamburger.contains(e.target) &&
      navbar.classList.contains("active")
    ) {
      toggleMenu();
    }
  });

  // Event delegation for closing the menu when clicking on a link
  document.addEventListener("click", (e) => {
    if (e.target.closest(".navbar-link, .navbar-button")) {
      if (navbar.classList.contains("active")) {
        toggleMenu();
      }
    }
  });
}
